import { Footer } from '@/components/footer/footer.tsx';
import { getStaticCacheControl } from '@/routes-utils/get-static-cache-control.ts';
import { Discover } from '@/routes/waitlist/discover/discover.tsx';
import { HowToUse } from '@/routes/waitlist/how-to-use/how-to-use.tsx';
import { welcomeGradient } from '@/routes/waitlist/main-waitlist/main-waitlist.css.ts';
import { MainWaitlist } from '@/routes/waitlist/main-waitlist/main-waitlist.tsx';
import { SubWaitlist } from '@/routes/waitlist/sub-waitlist/sub-waitlist.tsx';
import {
  footerGradientContainer,
  gradientContainer,
  landingContainer,
} from './page.css.ts';

export const headers = () => getStaticCacheControl();

export default function Landing() {
  return (
    <div className={landingContainer}>
      <div className={welcomeGradient}>
        <MainWaitlist />
      </div>
      <div className={gradientContainer}>
        <HowToUse />
        <Discover />
      </div>
      <div className={footerGradientContainer}>
        <SubWaitlist />
        <Footer />
      </div>
    </div>
  );
}
