import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { useLocation } from 'react-router';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { SynopticLogoIcon } from '@synoptic/ui-kit/icons/react/synoptic-logo.tsx';
import { header, headerLoginLink } from './main-waitlist.css';
import { sanitizeRedirectParam } from '@/routes-utils/sanitize-redirect-param.ts';

export const Header = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromParam = queryParams.get('from');
  const from = fromParam ? sanitizeRedirectParam(fromParam) : undefined;

  return (
    <div className={header}>
      <SynopticLogoIcon width={107} />
      <Button variant={'tertiary'} asChild>
        <Link
          to={paths.makeLogin(from)}
          className={headerLoginLink}
          prefetch="intent"
        >
          Login
        </Link>
      </Button>
    </div>
  );
};
